@keyframes offset {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-nevittagold {
  to {
    fill: #d2ae6d;
    stroke: transparent;
  }
}
.nevittagold {
  stroke-width: 2;
  stroke-dasharray: 1212;
  stroke-dashoffset: 1212;
  fill: transparent;
}

.nevittagold {
  stroke: #d2ae6d;
  animation: offset 20s linear forwards, fill-nevittagold 2s 2s forwards;
}
